<template>
    <div class="settings">
        <Tools />
    </div>
</template>

<script>
import Tools from '@/components/settings/tools/index.vue';

export default {
    name: 'SettingsTools',
    components: {
        Tools,
    },
};
</script>
