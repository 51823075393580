<template>
    <div>
        <GenericCrud
            route-name="SettingsTools"
            route-action="list"
            :navbar-title="$t('settings.tool.buttonLabel')"
            :navbar-back-route-without-history="{ name: 'Settings' }"
            :main-list-filter-label="$t('settings.tool.filterLabel')"
            main-fist-scroll-to-selected
            :storeData="{
                mainListItems: {
                    action: 'tools/fetch',
                    payload: { force: true },
                    options: null,
                    itemText: 'title'
                },
                mainItemById: {
                    action: 'tools/fetchById',
                    payload: null,
                    options: null,
                },
                edit: {
                    action: 'tools/edit',
                    payload: null,
                    options: null,
                    refetch: true,
                    hideButton: true
                },
                add: {
                    action: 'tools/create',
                    payload: null,
                    options: null,
                },
                delete: {
                    action: 'tools/delete',
                    payload: null,
                    options: null,
                    hideButton: true
                },
                findUsage: {
                    action: 'tools/findUsage',
                    payload: null,
                    options: null,
                    hideButton: true
                },
                replace: {
                    action: 'tools/replaceItemInRecord',
                    options: null,
                    hideButton: true
                },
            }"
            :newItemModelFields="{
                clientId: null,
                alias: null,
                systemId: null,
                systemOwner: null,
                description: null,
                descriptionUrl: null,
                cloud: 'NOT_EXEMPTED',
                supplierAssessment: null,
                iso27001Certified: null,
                onPremise: null,
                lifecyclePhaseName: null,
                securityControls: [],
                vendorId: null
            }"
        >
            <template v-slot:editorMask="x">
                <EditorMask
                    v-model="x.selectedMainItem"
                    :securityControlItems="securityControlItems"
                    :useVendor="getCompanyGroupSettings('useVendor')"
                    :vendorItems="vendorItems"
                    :key="x.id"
                    :isWaiting="x.isWaiting"
                    :isModified="x.isModified"
                    @modified="x.onSetModified"
                    @onSaveItem="x.onSaveItem"
                    @onDeleteItem="x.onDeleteItem"
                    @onFindUsage="x.onFindUsage"
                    @onCreateItem="x.onCreateItem"
                />
            </template>
        </GenericCrud>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import GenericCrud from '@/components/generic/GenericCrud.vue';
import EditorMask from './EditorMask.vue';

export default {
    name: 'SettingsToolsIndex',
    components: {
        GenericCrud,
        EditorMask,
    },
    i18n: {
        messages: {
            en: require('@/locales/Settings/en.json'),
            de: require('@/locales/Settings/de.json'),
        },
    },
    computed: {
        ...mapGetters({
            securityControlItems: 'tools/getSecurityControlItems',
            vendorItems: 'vendor/getItems',
            getCompanyGroupSettings: 'companyGroupSettings/get',
        }),
    },
    methods: {
        ...mapActions({
            fetchSecurityControlItems: 'tools/fetchSecurityControlItems',
            fetchVendorItems: 'vendor/fetch',
            fetchSettings: 'companyGroupSettings/fetch',
        }),
        async fetchData() {
            this.$wait.start('pageLoader');
            await Promise.all([this.fetchSettings()]);
            await Promise.all([this.fetchSecurityControlItems()]);
            if(this.getCompanyGroupSettings('useVendor')) {
                await Promise.all([this.fetchVendorItems()]);
            }
            this.$wait.end('pageLoader');
        },
    },
    created() {
        this.fetchData();
    },
};
</script>
