<template>
    <v-container fluid class="ma-0 mb-3 pa-0 pl-2 pt-3">
        <v-row class="justify-space-arround">
            <v-col cols="12">
                <v-card class="default" outlined height="100%">
                    <EditorToolbar
                        :headline="model.title"
                        :isModified="isModified"
                        :isWaiting="isWaiting"
                        :editMode="editMode"
                        @onSaveItem="$emit('onSaveItem')"
                        @onDeleteItem="$emit('onDeleteItem')"
                        @onCreateItem="$emit('onCreateItem')"
                        @onFindUsage="$emit('onFindUsage')"
                        :buttons="['new', 'save', 'delete', 'usageAndReplacement']"
                        />
                    <v-container fluid>
                        <v-row no-gutters>
                            <v-col cols="12" xl="8">
                                <v-card-text>
                                    <v-text-field
                                        v-model="model.title"
                                        :label="$t('name')"
                                        :error-messages="errors.collect('title')"
                                        data-vv-name="title"
                                        v-validate="'required'"
                                        spellcheck="true"
                                        autofocus
                                        @input="$emit('modified', $event)"
                                    ></v-text-field>
                                    <v-text-field
                                        v-model="model.alias"
                                        :label="$t('alias')"
                                        spellcheck="true"
                                        @input="$emit('modified', $event)"
                                    ></v-text-field>
                                    <v-select
                                        v-if="useVendor"
                                        v-model="model.vendorId"
                                        :items="vendorItems"
                                        :label="$t('settings.tool.vendor.fieldLabel')"
                                        data-vv-name="vendor"
                                        item-text="name"
                                        item-value="id"
                                        clearable
                                        @change="$emit('modified', $event)"
                                    ></v-select>
                                    <v-text-field
                                        v-model="model.systemId"
                                        :label="$t('settings.tool.systemId.fieldLabel')"
                                        spellcheck="true"
                                        @input="$emit('modified', $event)"
                                    ></v-text-field>
                                    <v-text-field
                                        v-model="model.systemOwner"
                                        :label="$t('settings.tool.systemOwner.fieldLabel')"
                                        spellcheck="true"
                                        @input="$emit('modified', $event)"
                                    ></v-text-field>
                                    <v-textarea
                                        v-model="model.description"
                                        :label="$t('settings.tool.descriptionField.fieldLabel')"
                                        spellcheck="true"
                                        :rows="2"
                                        @input="$emit('modified', $event)"
                                    ></v-textarea>
                                    <v-text-field
                                        v-model="model.descriptionUrl"
                                        :label="$t('settings.tool.descriptionUrl.fieldLabel')"
                                        spellcheck="true"
                                        @input="$emit('modified', $event)"
                                    ></v-text-field>
                                    <v-select
                                        v-model="model.cloud"
                                        :items="cloudItems"
                                        :label="$t('settings.tool.cloud.fieldLabel')"
                                        :error-messages="errors.collect('cloud')"
                                        data-vv-name="cloud"
                                        v-validate="'required'"
                                        item-text="title"
                                        item-value="value"
                                        clearable
                                        @change="$emit('modified', $event)"
                                    ></v-select>
                                    <v-switch
                                        v-model="model.supplierAssessment"
                                        color="primary"
                                        :label="$t('settings.tool.supplierAssessment.fieldLabel')"
                                        class="mt-0"
                                        @change="$emit('modified', $event)"
                                    ></v-switch>
                                    <v-switch
                                        v-model="model.iso27001Certified"
                                        color="primary"
                                        :label="$t('settings.tool.iso27001Certified.fieldLabel')"
                                        class="mt-0"
                                        @change="$emit('modified', $event)"
                                    ></v-switch>
                                    <v-switch
                                        v-model="model.onPremise"
                                        color="primary"
                                        :label="$t('settings.tool.onPremise.fieldLabel')"
                                        :hint="$t('settings.tool.onPremise.hint')"
                                        persistent-hint
                                        class="mt-0"
                                        @change="$emit('modified', $event)"
                                    ></v-switch>
                                    <v-select
                                        v-model="model.lifecyclePhaseName"
                                        :items="lifecycleItems"
                                        :label="$t('settings.tool.lifecycle.fieldLabel')"
                                        data-vv-name="lifecyclePhaseName"
                                        item-text="title"
                                        item-value="name"
                                        clearable
                                        @change="$emit('modified', $event)"
                                    ></v-select>
                                    <v-autocomplete
                                        v-model="model.securityControls"
                                        :label="$t('settings.tool.securityControls.fieldLabel')"
                                        :items="securityControlItems"
                                        hide-selected
                                        multiple
                                        item-text="title"
                                        item-value="id"
                                        return-object
                                        @change="$emit('modified', $event)"
                                    >
                                        <template v-slot:selection="{ index }">
                                            <span
                                                v-if="index === 0"
                                                class="grey--text text-caption"
                                                >{{ model.securityControls.length }}
                                                {{ $t('selected') }}</span
                                            >
                                        </template>
                                    </v-autocomplete>
                                </v-card-text>
                            </v-col>
                            <v-col cols="12" xl="4">
                                <v-card-title class="overline primary--text">{{ $t('settings.tool.assignedSecurityControls') }}</v-card-title>
                                <v-card-text style="max-height: 50vh; overflow-y: auto">
                                    <v-list dense>
                                        <template v-for="item in model.securityControls">
                                            <v-hover :key="item.id">
                                                <v-list-item
                                                    slot-scope="{ hover }"
                                                    ripple
                                                    @click="removeSecurityControl(item)"
                                                >
                                                    <v-list-item-content>
                                                        <v-list-item-title
                                                            v-html="item.title"
                                                            :class="`${hover ? 'line-through' : ''}`"
                                                        ></v-list-item-title>
                                                    </v-list-item-content>
                                                    <v-list-item-action>
                                                        <v-icon small v-if="hover" color="error">mdi-close</v-icon>
                                                    </v-list-item-action>
                                                </v-list-item>
                                            </v-hover>
                                        </template>
                                    </v-list>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { isUUID } from '@/components/vvt/ModelMixin.js';
import EditorToolbar from '../EditorToolbar.vue';
export default {
    name: 'SettingsToolsEditorMask',
    inject: {
        $validator: '$validator',
    },
    components: {
        EditorToolbar
    },
    props: {
        value: {
            type: Object,
            default: null
        },
        securityControlItems: {
            type: Array,
            default: () => []
        },
        vendorItems: {
            type: Array,
            default: () => []
        },
        isWaiting: {
            type: Boolean,
            default: false
        },
        isModified: {
            type: Boolean,
            default: false
        },
        useVendor: {
            type: Boolean,
            default: true
        },
    },
    i18n: {
        messages: {
            en: require('@/locales/Settings/en.json'),
            de: require('@/locales/Settings/de.json'),
        },
    },
    data() {
        return {
            model: this.value,
            cloudItems: [
                {
                    value: 'YES',
                    title: this.$t('yes'),
                },
                {
                    value: 'NO',
                    title: this.$t('no'),
                },
                {
                    value: 'NOT_EXEMPTED',
                    title: this.$t('notExcludable'),
                },
            ],
            lifecycleItems: [
                {
                    name: 'active',
                    title: this.$t('settings.tool.lifecycle.items.active'),
                },
                {
                    name: 'phase-out',
                    title: this.$t('settings.tool.lifecycle.items.phase-out'),
                }
            ]
        };
    },
    mounted() {
        if (isUUID(this.model.id)) {
            this.model.clientId = this.value.id;
        }
    },
    computed: {
        editMode() {
            return !isUUID(this.model.id);
        },
    },
    methods: {
        removeSecurityControl(item) {
            const index = this.model.securityControls.findIndex(
                x => x.id === item.id
            );
            this.model.securityControls.splice(index, 1);
            this.$emit('modified');
        },
    },
};
</script>
