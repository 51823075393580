var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('GenericCrud',{attrs:{"route-name":"SettingsTools","route-action":"list","navbar-title":_vm.$t('settings.tool.buttonLabel'),"navbar-back-route-without-history":{ name: 'Settings' },"main-list-filter-label":_vm.$t('settings.tool.filterLabel'),"main-fist-scroll-to-selected":"","storeData":{
            mainListItems: {
                action: 'tools/fetch',
                payload: { force: true },
                options: null,
                itemText: 'title'
            },
            mainItemById: {
                action: 'tools/fetchById',
                payload: null,
                options: null,
            },
            edit: {
                action: 'tools/edit',
                payload: null,
                options: null,
                refetch: true,
                hideButton: true
            },
            add: {
                action: 'tools/create',
                payload: null,
                options: null,
            },
            delete: {
                action: 'tools/delete',
                payload: null,
                options: null,
                hideButton: true
            },
            findUsage: {
                action: 'tools/findUsage',
                payload: null,
                options: null,
                hideButton: true
            },
            replace: {
                action: 'tools/replaceItemInRecord',
                options: null,
                hideButton: true
            },
        },"newItemModelFields":{
            clientId: null,
            alias: null,
            systemId: null,
            systemOwner: null,
            description: null,
            descriptionUrl: null,
            cloud: 'NOT_EXEMPTED',
            supplierAssessment: null,
            iso27001Certified: null,
            onPremise: null,
            lifecyclePhaseName: null,
            securityControls: [],
            vendorId: null
        }},scopedSlots:_vm._u([{key:"editorMask",fn:function(x){return [_c('EditorMask',{key:x.id,attrs:{"securityControlItems":_vm.securityControlItems,"useVendor":_vm.getCompanyGroupSettings('useVendor'),"vendorItems":_vm.vendorItems,"isWaiting":x.isWaiting,"isModified":x.isModified},on:{"modified":x.onSetModified,"onSaveItem":x.onSaveItem,"onDeleteItem":x.onDeleteItem,"onFindUsage":x.onFindUsage,"onCreateItem":x.onCreateItem},model:{value:(x.selectedMainItem),callback:function ($$v) {_vm.$set(x, "selectedMainItem", $$v)},expression:"x.selectedMainItem"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }